export const ADMIN_ROUTER = "/admin";
export const LOGIN_ROUTER = "/login";
export const REGISTRATION_ROUTER = "/registration";
export const SHOP_ROUTER = "/";
export const PRODUCT_ROUTER = "/product";
export const NEWS_ROUTER = "/news";
export const CONTACTS_ROUTER = "/contacts";
export const DELIVERY_ROUTER = "/delivery";
export const BUY_ROUTER = "/buy";
export const BUYFORM_ROUTER = "/buyform";
export const LS_ROUTER = "/ls";
export const CREATE_ROUTER = "/admin/create";
export const USER_ROUTER = "/admin/user";
export const ADRESS_ROUTER = "/admin/adress";
export const NEWSADD_ROUTER = "/admin/news";
export const DELIVERYADD_ROUTER = "/admin/delivery";
export const GIFT_ROUTER = "/admin/gift";
export const FOOTER_ROUTER = "/admin/footer";
export const MANUFACTURERS_ROUTER = "/admin/manufacturers";
export const PRODUCTADD_ROUTER = "/admin/product";
export const ORDER_ROUTER = "/order-confirmation";
export const ORDER2_ROUTER = "/confirmation2";
export const ORDER3_ROUTER = "/confirmation3";
export const IMG_ROUTER = "/admin/img";
export const CARTINFO_ROUTER = "/cartinfo";
export const RESETPASSWORD = "/reset-password";