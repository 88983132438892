// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.order-confirmation-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #1A1A1A;
}

.order-confirmation-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: white;
}

.order-confirmation-title {
  font-size: 48px;
  font-family: 'Gilroy-ExtraBold';
  margin-bottom: 10px;
}

.order-confirmation-subtitle {
  font-size: 36px;
  font-family: 'Gilroy-ExtraBold';
  margin-bottom: 10px;
}

.order-confirmation-description {
  font-size: 18px;
  font-family: 'MyriadPro-Regular';
  margin-bottom: 40px;
}

.order-confirmation-logo {
  width: 200px; /* Подгоните размер под свои нужды */
  height: auto;
}

@media (max-width: 768px) {
  .order-confirmation-title {
    font-size: 38px;
  }

  .order-confirmation-subtitle {
    font-size: 26px;
  }

  .order-confirmation-description {
    font-size: 14px;
  }

  .order-confirmation-container {
    margin-top: 20px;
  }
  
}`, "",{"version":3,"sources":["webpack://./src/style/OrderConfirmation.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,iBAAiB;EACjB,yBAAyB;AAC3B;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,kBAAkB;EAClB,YAAY;AACd;;AAEA;EACE,eAAe;EACf,+BAA+B;EAC/B,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,+BAA+B;EAC/B,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,gCAAgC;EAChC,mBAAmB;AACrB;;AAEA;EACE,YAAY,EAAE,oCAAoC;EAClD,YAAY;AACd;;AAEA;EACE;IACE,eAAe;EACjB;;EAEA;IACE,eAAe;EACjB;;EAEA;IACE,eAAe;EACjB;;EAEA;IACE,gBAAgB;EAClB;;AAEF","sourcesContent":[".order-confirmation-wrapper {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  min-height: 100vh;\n  background-color: #1A1A1A;\n}\n\n.order-confirmation-container {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  text-align: center;\n  color: white;\n}\n\n.order-confirmation-title {\n  font-size: 48px;\n  font-family: 'Gilroy-ExtraBold';\n  margin-bottom: 10px;\n}\n\n.order-confirmation-subtitle {\n  font-size: 36px;\n  font-family: 'Gilroy-ExtraBold';\n  margin-bottom: 10px;\n}\n\n.order-confirmation-description {\n  font-size: 18px;\n  font-family: 'MyriadPro-Regular';\n  margin-bottom: 40px;\n}\n\n.order-confirmation-logo {\n  width: 200px; /* Подгоните размер под свои нужды */\n  height: auto;\n}\n\n@media (max-width: 768px) {\n  .order-confirmation-title {\n    font-size: 38px;\n  }\n\n  .order-confirmation-subtitle {\n    font-size: 26px;\n  }\n\n  .order-confirmation-description {\n    font-size: 14px;\n  }\n\n  .order-confirmation-container {\n    margin-top: 20px;\n  }\n  \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
